// extracted by mini-css-extract-plugin
export var ArtistDescription = "Phase2-module--ArtistDescription--LXPCu";
export var CardWrapper = "Phase2-module--CardWrapper--UUiS3";
export var CarrouselWrapper = "Phase2-module--CarrouselWrapper--HzYr7";
export var DescriptionWrapper = "Phase2-module--DescriptionWrapper--9yi3v";
export var ExpoWrapper = "Phase2-module--ExpoWrapper--c9Z3R";
export var NameWrapper = "Phase2-module--NameWrapper--7Nr30";
export var PdpWrapper = "Phase2-module--PdpWrapper--dadxu";
export var PhotosWrapper = "Phase2-module--PhotosWrapper--vrYDH";
export var ProfilWrapper = "Phase2-module--ProfilWrapper--vq5O+";
export var VideoWrapper = "Phase2-module--VideoWrapper--9Mtud";
export var VideosWrapper = "Phase2-module--VideosWrapper--Rmfi-";
export var WorkWrapper = "Phase2-module--WorkWrapper--AZkco";
export var WorksWrapper = "Phase2-module--WorksWrapper--bFiNo";
export var Wrapper = "Phase2-module--Wrapper--wv1fF";